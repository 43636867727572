/**
 * Created by jerry on 2020/03/02.
 * 权限api
 */
import * as API from '.'

export default {
    // 权限
    getSidebar: params => {
        return API.POST('api/account/getPCMenuPermission', params)
    },
    // 路由权限
    getPCPermissionRouter: params => {
        return API.POST('api/account/getPCPermissionRouter', params)
    }
}
